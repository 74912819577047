<template>
    <div class="zykt-main p-1 h-100 box-border">
        <el-card class="zy-card ova yes-head h-100">
            <div class="py-1">
                <div class="d-flex ai-center mb-1">
                    <el-input
                        class="mr-1"
                        clearable
                        style="width:150px"
                        v-model="logsModel.operator"
                        placeholder="请输入操作人"
                    ></el-input>
                    <el-input
                        class="mr-1"
                        clearable
                        style="width:150px"
                        v-model="logsModel.info"
                        placeholder="请输入操作内容"
                    ></el-input>
                    <el-date-picker
                        class="mr-1"
                        v-model="picker"
                        type="daterange"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        value-format="yyyy-MM-dd"
                    >
                    </el-date-picker>
                    <el-button type="primary" @click="searchLog">
                        搜索
                    </el-button>
                </div>
            </div>

            <zy-table :tbHead="tbHead" :tbBody="tbBody" highlightCurrentRow>
            </zy-table>
            <zy-page
                class="search-site-page mt-1"
                @current-change="handlePageCurrentChange"
                @size-change="handlePageSizeChange"
                :pageModel="pageModel"
                :total="total"
                :pageSizes="[15, 20, 50, 300]"
            ></zy-page>
        </el-card>
    </div>
</template>
<script>
import ZyTable from '@/components/ZyTable/index'
import ZyPage from '@/mixins/ZyPage'
import Excel from '@/mixins/excel'
export default {
    components: { ZyTable },
    mixins: [ZyPage, Excel],
    data() {
        return {
            logsModel: {},
            // 操作时间
            picker: [],
            // 表头
            tbHead: [
                {
                    prop: 'id',
                    label: '序号',
                    width: 80
                },
                {
                    prop: 'operator',
                    label: '操作人',
                    width: 80
                },
                {
                    prop: 'info',
                    label: '操作内容'
                },
                {
                    prop: 'recordTime',
                    label: '操作时间',
                    width: 180
                }
            ],

            // 内容
            tbBody: []
        }
    },
    created() {
        // this.initPeriodPickerModel() //初始化日期
        this.getLogs()
    },
    methods: {
        // 初始化日期
        // initPeriodPickerModel() {
        //     const nowDate = new Date()
        //     const endTime = this.$moment(nowDate).format('YYYY-MM-DD')
        //     const startTime = this.$moment(nowDate).format('YYYY-MM-DD')
        //     this.picker = [startTime, endTime]
        // },

        // 获取表格数据
        async getLogs() {
            const pageNum = this.pageModel.pagenum.toString()
            const pageSize = this.pageModel.pagesize.toString()

            const res = await this.$api.selectData.getLogs(pageNum, pageSize)

            this.tbBody = res.data.list
            this.total = res.data.total
            if (res.code != 200) return this.$message.error('获取数据失败!')
        },

        async searchLog() {
            const pageNum = this.pageModel.pagenum.toString()
            const pageSize = this.pageModel.pagesize.toString()
            const operator = this.logsModel.operator
            const info = this.logsModel.info
            const startTime = this.picker[0]
            const endTime = this.picker[1]
            const res = await this.$api.selectData.getLogs(
                pageNum,
                pageSize,
                operator,
                info,
                startTime,
                endTime
            )
            this.tbBody = res.data.list
            this.total = res.data.total
            if (res.code != 200) return this.$message.error('获取数据失败!')
        },
        // 分页回调
        getPageList() {
            return this.getLogs()
        }
    }
}
</script>
